.icon-arrow-round {
	width: 22px;
	height: 22px;
}

.icon-arrow-sq {
	width: 19px;
	height: 19px;
}

.icon-cross {
	width: 16px;
	height: 16px;
}

.icon-facebook {
	width: 24px;
	height: 24px;
}

.icon-instagram {
	width: 24px;
	height: 24px;
}

.icon-menu {
	width: 16px;
	height: 11.42px;
}

